
.lida-start {
  width: 100%;
  height: 100vh;
}

.lida-start .lida-show {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 3px;
}

.lida-start .lida-show h3 {
  color: #00F;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.6px;
  margin-block: 0px;
}

.lida-start .lida-show h2 {
  color: #00F;
  text-align: center;
  font-family: Inter;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.5px;
  margin-block: 0px;
}

.lida-start .lida-show .button-show {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #00F;
  margin-block: 30px;
  text-decoration: none;
}

.lida-start .lida-show .button-show:hover {
  cursor: pointer;
}

.lida-start .lida-show .button-show:active {
  background: #00F;
}

.lida-start .lida-show .button-show:active h4 {
  color: white;
}

.lida-start .lida-show .button-show h4 {
  color: #00F;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.75px;
  margin-block: 0px;
  user-select: none;
}

.lida-start .page1 {
  background: url('../assets/1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.lida-start .page2 {
  background: url('../assets/2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.lida-start .lida-show .carousel-indexes {
  position: fixed;
  left: 50%;
  bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 20px;
  transform: translate(-50%, 0);
}

.lida-start .lida-show .carousel-indexes .carousel-index {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 1px solid #00F;
}

.lida-start .lida-show .carousel-indexes .carousel-index:hover {
  cursor: pointer;
}

.lida-start .lida-show .carousel-indexes .selected {
  background: #00F;
}