
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Syne:wght@400;500;600;700;800&display=swap');

body, html {
  overflow-x: hidden;
}

.lida {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 50px;
  overflow-x: hidden;
}

.loading {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  z-index: 50000;
  animation: 2.1s showcase;
  display: grid;
  place-content: center;

}

.loading svg {
  filter: drop-shadow(0px 0px 10px #0000FF);
  animation: 2000ms pulse linear infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
    filter: drop-shadow(0px 0px 0px #0000FF);
    scale: 1;
  }
  50% {
    opacity: 1;
    filter: drop-shadow(0px 0px 10px #0000FF);
    scale: 1;
  }
  60% {
    scale: 100;
  }
  100% {
    opacity: 0.2;
    filter: drop-shadow(0px 0px 0px #0000FF);
    scale: 10;
  }
}

@keyframes showcase {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
    z-index: -100;
    pointer-events: none;
  }
  100% {
    opacity: 0;
    z-index: -100;
  }
}

.lida .navigator {
  margin-top: 1vw;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.lida .navigator .logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
}

.lida .navigator .logo .top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 5px;
}

.lida .navigator .logo .top h1 {
  color: #00F;
  font-family: Syne;
  font-size: 75.108px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -5.633px;
  margin-block: 0px;
}

.lida .navigator .logo h2 {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.75px;
  margin-block: 0px;
}

.lida .translator {
  width: min(1550px, 90vw);
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  border-radius: 14px;
  border: 1px solid #CBD5E1;
  background: #FFF;
}

.lida .translator .bar {
  align-self: stretch;
  border-bottom: solid 1px #CBD5E1;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 10px;
  padding: 0px 10px;
  flex-shrink: 0;
  flex-grow: 0;
}


.lida .translator .bar .selector {
  height: 40px;
  border-radius: 6px;
  border: 1px solid #CBD5E1;
  display: flex;
  flex-direction: row;
  padding: 0px 12px;
  align-items: center;
  gap: 11px;
}

.lida .translator .bar .selector:hover {
  cursor: pointer;
}

.lida .translator .bar .selector h3 {
  color: #0F172A;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  user-select: none;
}

.lida .translator .bar .swap {
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #CBD5E1;
}

.lida .translator .bar .swap:hover {
  cursor: pointer;
}

.lida .translator .spare {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 0px;
  overflow: hidden;
}

.lida .translator .spare .tz {
  display: flex;
  padding: 20px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #999;
  background: #CBD5E1;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  transition: 300ms;
}

.lida .translator .spare .tz:hover {
  cursor: pointer;
}

.lida .translator .spare .tz:active {
  background: #fcfdff;
  transition: 300ms;
}

.lida .translator .spare .tz h3 {
  color: #0F172A;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-block: 0px;
}

.lida .translator .spare .left, .lida .translator .spare .right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lida .translator .spare div > textarea {
  width: 96%;
  height: 96%;
  border: none;
  outline: none;
  resize: none;
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.75px;
}

.lida .translator .spare div > textarea::placeholder {
  color: #CBD5E1;
}

.lida .translator .shift {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
  background: #CBD5E1;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #CBD5E1;
  animation: ease 300ms;
}

.lida .translator .shift .prop {
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 5px;
  border: solid 1px #CBD5E1;
  border-radius: 7px;
}

.lida .translator .shift .normal:hover {
  background: rgba(245, 249, 255, 0.5);
  cursor: pointer;
}

.lida .translator .shift .prop h3 {
  color: #0F172A;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 10px;
  user-select: none;
  margin-block: 1px;
}

.lida .translator .shift .active {
  border: solid 1px #ffffff;
}

.lida .translator .shift .hidden {
  border: solid 1px #ffffff;
  background: url('../assets/nacc.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.6;
}

.lida .schemas {
  width: 100%;
  aspect-ratio: calc(1550/778);
  background: url('../assets/schema.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.lida .paragraph {
  width: min(90vw, 810px);
}

.lida .paragraph h2 {
  color: #000;
  font-family: Syne;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.25px;
  margin-block: 0px;
}

.lida .paragraph p {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  margin-block: 0px;
}


.lida .ready {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-block: 50px;
}

.lida .ready h2 {
  color: #000;
  font-family: Syne;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.5px;
  margin-block: 0px;
}

.lida .modles {
  width: 80vw;
  aspect-ratio: calc(685/597);
  height: auto;
  background: url('../assets/fig.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


@media only screen and (max-aspect-ratio: 1) {
  .lida .translator .spare  {
    flex-direction: column;
  }
  .lida .translator .spare .tz {
    align-self: stretch;
    margin-inline: 10px;
  }
}